export { default as About } from './About';
export { default as AccessDenied } from './AccessDenied';
export * from './AddEntity';
export { default as AdditionalOperations, type TOperationProps } from './AdditionalOperations';
export { default as Alert } from './Alert';
export { default as AssociatedPartiesPanel } from './AssociatedPartiesPanel';
export { default as FatButton } from './Button/FatButton';
export { default as LargeButton } from './Button/LargeButton';
export { default as Carousel } from './Carousel';
export { default as CircleLabel } from './CircleLabel';
export { default as Confirmation, type TConfirmation } from './Confirmation';
export { default as ContentDetailsDisplay } from './ContentDetailsDisplay';
export { default as CopyableText } from './CopyableText';
export { default as CustomerContact } from './CustomerContact';
export { default as DataGrid } from './DataGrid';
export type { TDataGridColumns, TDataGridProps, TDataGridRows } from './DataGrid';
export { default as DataTable } from './DataTable/DataTable';
export type { TDataTable } from './DataTable/DataTable';
export { default as TableCell } from './DataTable/TableCell';
export { default as DateDisplay } from './DateDisplay';
export { default as DisplayAddress } from './DisplayAddress';
export { default as DocumentNotFound } from './DocumentNotFound';
export { default as DocumentNotSupported } from './DocumentNotSupported';
export { default as DocumentReader } from './DocumentReader';
export { default as EmailInputWithContinueButton } from './EmailInputWithContinueButton';
export * from './FaceLiveness';
export { default as FavIcons } from './FavIcons';
export { default as FileDragAndDropSelector } from './FileDragAndDropSelector';
export { default as FileSelector } from './FileSelector';
export { default as FlexBox } from './FlexBox';
export { default as FlexPaper } from './FlexPaper';
export { default as GlobalStyleOverride } from './GlobalStyleOverride';
export { default as Group } from './Group';
export {
  default as HeadWithContentSecurityPolicy,
  type THeadProps,
} from './HeadWithContentSecurityPolicy';
export { useHideDocumentScrollbar } from './Hooks/useHideDocumentScrollbar';
export { usePaginationModel } from './Hooks/usePaginationModel';
export { IconLink } from './IconLink';
export { IconLinkOpenNew } from './IconLinkOpenNew';
export {
  CarouselIdentityCards,
  CompactIdentityCard,
  CreateNewIdentity,
  IdentityCard,
  IdentityCards,
  IdentityStatus,
  IdentityStatusChip,
  IdentityType,
  type IdentityCardProps,
  type IdentityCardsProps,
} from './Identities';
export {
  default as InformationDisplay,
  type TInformationDisplayProps,
  type TInformationDisplayRows,
  type TInformationItemDisplay,
} from './InformationDisplay';
export { default as Instruction } from './Instruction';
export { default as InvalidLink } from './InvalidLink';
export {
  default as InviteCustomer,
  InviteCustomerDisplayVariant,
  type TInviteCustomerProps,
} from './InviteCustomer';
export { default as KYC, type TKycRow, type TKycRows } from './KYC';
export { default as Landing } from './Landing';
export { default as LogoBox } from './Logo';
export {
  default as MultiFileUploader,
  fileSizeDisplay,
  type TExistingUploadedFile,
} from './MultiFileUploader';
export type {
  TFileToDelete,
  TFileUploadResult,
  TFileUploadStatus,
  TFileUploadUrl,
  TFilesUploadStatus,
} from './MultiFileUploader';
export {
  default as useDocumentUploader,
  type DocumentUploaderServiceCallsProps,
} from './MultiFileUploader/useDocumentUploader';
export { default as NameValueDisplay } from './NameValueDisplay';
export { default as HomeLink } from './Nav/HomeLink';
export { default as SideNav, type TBasicSideNavProps } from './Nav/SideNav';
export { default as SideNavLogo } from './Nav/SideNavLogo';
export { default as TopBar } from './Nav/TopBar';
export { default as TopBarV2 } from './Nav/TopBarV2';
export { default as NoDataFound } from './NoDataFound';
export { default as NonSSRWrapper } from './NonSSRWrapper';
export {
  default as OrganisationSearchAutoComplete,
  type TOrganisationSearchAutoCompleteProps,
} from './Organisation/OrganisationSearchAutoComplete';
export { default as OrganisationSelection } from './Organisation/OrganisationSelection';
export {
  default as OrganisationSwitcher,
  type TOrganisationSwitcherProps,
} from './OrganisationSwitcher';
export {
  InitialCredentialSetup,
  OTPSettings,
  default as PasswordlessLogin,
  SetupAuthenticatorApp,
} from './PasswordlessLogin';
export type {
  TInitialCredentialSetupProps,
  TLoginProps,
  TSetupAuthenticatorAppProps,
} from './PasswordlessLogin';
export { default as PEP } from './PEP';
export * from './Permission';

export { default as ClientLogo } from './ClientLogo';
export * from './ColoredIconAndChip';
export { default as SearchAutocomplete } from './ComboBox';
export { default as EmailInput } from './EmailInput';
export * from './Form';
export * from './IndividualPortrait';
export * from './material-ui-confirm';
export * from './Notification';
export * from './PasswordlessLogin';
export { default as PrivacyPolicyLink } from './PrivacyPolicyLink';
export { default as ResponsiveBox } from './ResponsiveBox';
export { default as Risk } from './Risk';
export { default as RiskLevelChip } from './Risk/RiskLevelChip';
export { default as RiskLevelIcon } from './Risk/RiskLevelIcon';
export * from './Risk/types';
export * from './Search';
export { default as Search } from './Search';
export { default as SearchItemDisplay } from './SearchItemDisplay';
export { default as SelectItemDrawer } from './SelectItemDrawer/SelectItemDrawer';
export { default as Declaration } from './Declaration';
export { default as SignUp, type TSignUpApiRequestAction, type TSignUpProps } from './SignUp';
export { default as SignUpCodeInput } from './SignUpCodeInput';
export { default as StepsWizard } from './StepsWizard';
export type { TStep, TStepContent, TSteps } from './StepsWizard';
export { default as SelectDocumentToUploadDrawer } from './SupportDocuments/SelectDocumentToUploadDrawer';
export { default as SupportDocumentDetailsView } from './SupportDocuments/SupportDocumentDetailsView';
export { default as UploadForVerification } from './SupportDocuments/UploadForVerification';
export * from './SupportDocuments/utils';
export { default as TaskRequestPanel, type TTaskRequestPanelProps } from './TaskRequestPanel';
export { default as TermsOfUseLink } from './TermsOfUseLink';
export { default as TextEditor } from './TextEditor';
export * from './Thumbnail';
export { default as Title } from './Title';
export * from './types';
export * from './utils';
export { default as VerificationStatus } from './VerificationStatus';
export { default as WholesaleCertificate } from './WholesaleCertificate';

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
    stroke?: string;
  }
  interface PaletteColor {
    lighter?: string;
    darker?: string;
    stroke?: string;
  }
}
