'use client';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import React, { useEffect } from 'react';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';
import { CarouselIdentityCards, FormSelect, IdentityCard, Declaration } from '../index';
import OrganisationSearchAutoComplete, {
  TOrganisationSearchAutoCompleteProps,
} from '../Organisation/OrganisationSearchAutoComplete';
import OrganisationSelection from '../Organisation/OrganisationSelection';
import { TIdentity, TOrganisation } from './types';

export type TApproachOfIdentityToShare = 'dropdown' | 'cardOnly' | 'carousel';

export type TNewPermissionProps = {
  reset?: UseFormReset<FieldValues>;
  control: Control;
  selectedIdentity?: TIdentity;
  identities: TIdentity[];
  orgSearchProps?: TOrganisationSearchAutoCompleteProps;
  handleChangeOrganisationSelection?: () => void;
  handleChangeSelectedIdentity?: (newSelectedIdentity: TIdentity) => void;
  initialOrganisation?: TOrganisation;
  isOrganisationModifiable?: boolean;
  approachOfIdentityToShare?: TApproachOfIdentityToShare;
};

export default function NewPermission({
  reset,
  control,
  selectedIdentity,
  identities,
  orgSearchProps = {},
  handleChangeOrganisationSelection,
  handleChangeSelectedIdentity,
  initialOrganisation,
  isOrganisationModifiable = true,
  approachOfIdentityToShare = 'dropdown',
}: TNewPermissionProps) {
  const [selectedOrganisation, setSelectedOrganisation] =
    React.useState<TOrganisation>(initialOrganisation);

  const { handleValueChange } = orgSearchProps;
  const handleSelectOrganisation: typeof handleValueChange = (event, value, reason, detail) => {
    setSelectedOrganisation(value as TOrganisation);
    handleValueChange?.(event, value, reason, detail);
  };

  const handleSelectedIdentityChanged = (event: SelectChangeEvent) => {
    const selectedIdentityId = event.target.value;
    const selectedIdentity = identities.find((identity) => identity.id === selectedIdentityId);
    if (selectedIdentity) {
      handleChangeSelectedIdentity?.(selectedIdentity);
    }
  };

  const actualChangeOrganisationSelection = () => {
    setSelectedOrganisation(undefined);
    handleChangeOrganisationSelection?.();
  };

  useEffect(() => {
    if (selectedIdentity && typeof reset === 'function') {
      reset({ identity: selectedIdentity.id });
    }
  }, [selectedIdentity]);

  return (
    identities?.length > 0 && (
      <Stack paddingY={tokens.spacingBase} paddingX="0" gap={tokens.spacingLg}>
        {approachOfIdentityToShare === 'carousel' && (
          <CarouselIdentityCards
            identities={identities}
            initialSelectedIdentity={selectedIdentity}
            onIdentitySelect={(identity) => handleChangeSelectedIdentity?.(identity)}
          />
        )}

        {approachOfIdentityToShare === 'dropdown' && (
          <FormSelect
            name="identity"
            label="Select Identity"
            control={control}
            defaultValue={selectedIdentity ? selectedIdentity.id : identities[0].id}
            onOptionChange={handleSelectedIdentityChanged}
          >
            {identities.map((identity) => (
              <MenuItem key={identity.id} value={identity.id}>
                {identity.name} - {identity.type}
              </MenuItem>
            ))}
          </FormSelect>
        )}
        {approachOfIdentityToShare === 'cardOnly' && (
          <Stack gap={tokens.spacingSm}>
            <Typography variant="h6">Identity to share</Typography>
            <IdentityCard {...selectedIdentity} onClick={() => {}} width="auto" />
          </Stack>
        )}
        <Typography variant="h6">Grant permission to</Typography>
        {selectedOrganisation ? (
          <OrganisationSelection
            organisation={selectedOrganisation}
            handleChange={actualChangeOrganisationSelection}
            modifiable={isOrganisationModifiable}
          />
        ) : (
          <OrganisationSearchAutoComplete
            {...orgSearchProps}
            handleValueChange={handleSelectOrganisation}
          />
        )}
        <Typography
          variant="caption"
          borderRadius={tokens.borderRadiusSm}
          paddingY={tokens.spacingXs}
          paddingRight={tokens.spacing3xs}
          paddingLeft={tokens.spacingXs}
          gap={tokens.spacing2xs}
          color="textSecondary"
        >
          By granting permission, I hereby confirm that
          <List sx={{ listStyle: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item', paddingLeft: tokens.spacingXs }}>
              the information provided for my verified status is true, accurate, and up to date.
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingLeft: tokens.spacingXs }}>
              I am aware that the product/service provider may need to access this information as
              part of their due diligence process.
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingLeft: tokens.spacingXs }}>
              I also acknowledge that providing false, misleading, or outdated information may lead
              to legal, professional, or personal consequences, for which I take full
              responsibility.
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingLeft: tokens.spacingXs }}>
              I understand that this disclaimer is binding and necessary to achieve the required
              levels of customer compliance and identity verification as required by the
              organisation.
            </ListItem>
          </List>
        </Typography>
        <Declaration declaration="We only share your verification status with the service provider. Your documents are NOT shared and remain safe in your VerifiMe wallet." />
      </Stack>
    )
  );
}
