import {
  TCountry,
  TCountryCode,
  TGender,
  TIdentityType,
  TIdentityTypes,
  TOption,
  TSupportDocumentStatus,
  TSupportDocumentStatusCode,
} from './types';
import { enumToOptions } from './utils';

export enum EntityStatus {
  Empty = 'Empty',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export enum Credential {
  HasCredential = 0,
  ByPasskey,
  ByAuthenticator,
}

/**
 * To support both ISO codes (2 letters and 3 letters),
 * such as AU/AUS for Australia
 */
export enum SupportedCountryCode {
  AU = 'AU',
  AUS = 'AUS',
  US = 'US',
  USA = 'USA',
  NZ = 'NZ',
  NZL = 'NZL',
  AT = 'AT',
  AUT = 'AUT',
  BE = 'BE',
  BEL = 'BEL',
  BR = 'BR',
  BRA = 'BRA',
  CA = 'CA',
  CAN = 'CAN',
  CH = 'CH',
  CHE = 'CHE',
  DE = 'DE',
  DEU = 'DEU',
  DK = 'DK',
  DNK = 'DNK',
  ES = 'ES',
  ESP = 'ESP',
  FR = 'FR',
  FRA = 'FRA',
  GB = 'GB',
  GBR = 'GBR',
  IT = 'IT',
  ITA = 'ITA',
  MX = 'MX',
  MEX = 'MEX',
  NL = 'NL',
  NLD = 'NLD',
  NO = 'NO',
  NOR = 'NOR',
  SE = 'SE',
  SWE = 'SWE',
  HK = 'HK',
  HKG = 'HKG',
  SG = 'SG',
  SGP = 'SGP',
}

const Australia = { code: SupportedCountryCode.AUS, label: 'Australia', phone: '61' };
const NewZealand = { code: SupportedCountryCode.NZL, label: 'New Zealand', phone: '64' };
const UnitedStates = { code: SupportedCountryCode.USA, label: 'United States', phone: '1' };
const Austria = { code: SupportedCountryCode.AUT, label: 'Austria', phone: '43' };
const Belgium = { code: SupportedCountryCode.BEL, label: 'Belgium', phone: '32' };
const Brazil = { code: SupportedCountryCode.BRA, label: 'Brazil', phone: '55' };
const Canada = { code: SupportedCountryCode.CAN, label: 'Canada', phone: '1' };
const Switzerland = { code: SupportedCountryCode.CHE, label: 'Switzerland', phone: '41' };
const Germany = { code: SupportedCountryCode.DEU, label: 'Germany', phone: '49' };
const Denmark = { code: SupportedCountryCode.DNK, label: 'Denmark', phone: '45' };
const Spain = { code: SupportedCountryCode.ESP, label: 'Spain', phone: '34' };
const France = { code: SupportedCountryCode.FRA, label: 'France', phone: '33' };
const UnitedKingdom = {
  code: SupportedCountryCode.GBR,
  label: 'United Kingdom',
  phone: '44',
};
const Italy = { code: SupportedCountryCode.ITA, label: 'Italy', phone: '39' };
const Mexico = { code: SupportedCountryCode.MEX, label: 'Mexico', phone: '52' };
const Netherlands = { code: SupportedCountryCode.NLD, label: 'Netherlands', phone: '31' };
const Norway = { code: SupportedCountryCode.NOR, label: 'Norway', phone: '47' };
const Sweden = { code: SupportedCountryCode.SWE, label: 'Sweden', phone: '46' };
const HongKong = { code: SupportedCountryCode.HKG, label: 'Hong Kong', phone: '852' };
const Singapore = { code: SupportedCountryCode.SGP, label: 'Singapore', phone: '65' };

export const SUPPORTED_COUNTRY_MAP: { [key in SupportedCountryCode]: TCountry } = {
  AU: { ...Australia, code: SupportedCountryCode.AU },
  US: { ...UnitedStates, code: SupportedCountryCode.US },
  NZ: { ...NewZealand, code: SupportedCountryCode.NZ },
  AT: { ...Austria, code: SupportedCountryCode.AT },
  BE: { ...Belgium, code: SupportedCountryCode.BE },
  BR: { ...Brazil, code: SupportedCountryCode.BR },
  CA: { ...Canada, code: SupportedCountryCode.CA },
  CH: { ...Switzerland, code: SupportedCountryCode.CH },
  DE: { ...Germany, code: SupportedCountryCode.DE },
  DK: { ...Denmark, code: SupportedCountryCode.DK },
  ES: { ...Spain, code: SupportedCountryCode.ES },
  FR: { ...France, code: SupportedCountryCode.FR },
  GB: { ...UnitedKingdom, code: SupportedCountryCode.GB },
  IT: { ...Italy, code: SupportedCountryCode.IT },
  MX: { ...Mexico, code: SupportedCountryCode.MX },
  NL: { ...Netherlands, code: SupportedCountryCode.NL },
  NO: { ...Norway, code: SupportedCountryCode.NO },
  SE: { ...Sweden, code: SupportedCountryCode.SE },
  HK: { ...HongKong, code: SupportedCountryCode.HK },
  SG: { ...Singapore, code: SupportedCountryCode.SG },
  AUS: Australia,
  USA: UnitedStates,
  NZL: NewZealand,
  AUT: Austria,
  BEL: Belgium,
  BRA: Brazil,
  CAN: Canada,
  CHE: Switzerland,
  DEU: Germany,
  DNK: Denmark,
  ESP: Spain,
  FRA: France,
  GBR: UnitedKingdom,
  ITA: Italy,
  MEX: Mexico,
  NLD: Netherlands,
  NOR: Norway,
  SWE: Sweden,
  HKG: HongKong,
  SGP: Singapore,
};

/**
 * Should use this generated country code thoughout codebase,
 * because we'd like to control only given countries by env to be used in the project.
 */
export const CountryCode = generateCountryCodeEnumByEnv();

export const COUNTRY_MAP: { [key in TCountryCode]?: TCountry } = {};
for (const countryCode in CountryCode) {
  if (countryCode in SUPPORTED_COUNTRY_MAP) {
    COUNTRY_MAP[countryCode as TCountryCode] =
      SUPPORTED_COUNTRY_MAP[countryCode as SupportedCountryCode];
  }
}

export const COUNTRIES: TCountry[] = Object.entries(COUNTRY_MAP).map(
  ([_, info]) => info,
) as TCountry[];

export const COUNTRY_ALPHA3_STATES = {
  [SupportedCountryCode.AUS]: [
    { code: 'ACT', label: 'ACT' },
    { code: 'NSW', label: 'NSW' },
    { code: 'NT', label: 'NT' },
    { code: 'QLD', label: 'QLD' },
    { code: 'SA', label: 'SA' },
    { code: 'TAS', label: 'TAS' },
    { code: 'VIC', label: 'VIC' },
    { code: 'WA', label: 'WA' },
  ],
  [SupportedCountryCode.USA]: [
    { code: 'AL', label: 'Alabama' },
    { code: 'AK', label: 'Alaska' },
    { code: 'AZ', label: 'Arizona' },
    { code: 'AR', label: 'Arkansas' },
    { code: 'CA', label: 'California' },
    { code: 'CO', label: 'Colorado' },
    { code: 'CT', label: 'Connecticut' },
    { code: 'DE', label: 'Delaware' },
    { code: 'FL', label: 'Florida' },
    { code: 'GA', label: 'Georgia' },
    { code: 'HI', label: 'Hawaii' },
    { code: 'ID', label: 'Idaho' },
    { code: 'IL', label: 'Illinois' },
    { code: 'IN', label: 'Indiana' },
    { code: 'IA', label: 'Iowa' },
    { code: 'KS', label: 'Kansas' },
    { code: 'KY', label: 'Kentucky' },
    { code: 'LA', label: 'Louisiana' },
    { code: 'ME', label: 'Maine' },
    { code: 'MD', label: 'Maryland' },
    { code: 'MA', label: 'Massachusetts' },
    { code: 'MI', label: 'Michigan' },
    { code: 'MN', label: 'Minnesota' },
    { code: 'MS', label: 'Mississippi' },
    { code: 'MO', label: 'Missouri' },
    { code: 'MT', label: 'Montana' },
    { code: 'NE', label: 'Nebraska' },
    { code: 'NV', label: 'Nevada' },
    { code: 'NH', label: 'New Hampshire' },
    { code: 'NJ', label: 'New Jersey' },
    { code: 'NM', label: 'New Mexico' },
    { code: 'NY', label: 'New York' },
    { code: 'NC', label: 'North Carolina' },
    { code: 'ND', label: 'North Dakota' },
    { code: 'OH', label: 'Ohio' },
    { code: 'OK', label: 'Oklahoma' },
    { code: 'OR', label: 'Oregon' },
    { code: 'PA', label: 'Pennsylvania' },
    { code: 'RI', label: 'Rhode Island' },
    { code: 'SC', label: 'South Carolina' },
    { code: 'SD', label: 'South Dakota' },
    { code: 'TN', label: 'Tennessee' },
    { code: 'TX', label: 'Texas' },
    { code: 'UT', label: 'Utah' },
    { code: 'VT', label: 'Vermont' },
    { code: 'VA', label: 'Virginia' },
    { code: 'WA', label: 'Washington' },
    { code: 'WV', label: 'West Virginia' },
    { code: 'WI', label: 'Wisconsin' },
    { code: 'WY', label: 'Wyoming' },
  ],
};

export const COUNTRY_ALPHA2_STATES = {
  [SupportedCountryCode.AU]: COUNTRY_ALPHA3_STATES[SupportedCountryCode.AUS],
  [SupportedCountryCode.US]: COUNTRY_ALPHA3_STATES[SupportedCountryCode.USA],
};

export const COUNTRY_STATES = {
  ...COUNTRY_ALPHA3_STATES,
  ...COUNTRY_ALPHA2_STATES,
};

export const GENDERS: TGender[] = [
  { code: 'female', label: 'Female' },
  {
    code: 'male',
    label: 'Male',
  },
  { code: 'other', label: 'Other' },
  { code: 'notToSay', label: 'Prefer not to say' },
];

function generateCountryCodeEnumByEnv() {
  const supportedCountryCodes = Object.keys(SupportedCountryCode);
  const envCountryCodes: TCountryCode[] = process?.env?.NEXT_PUBLIC_COUNTRY_CODES?.split(
    ',',
  ) as TCountryCode[];
  const isValidCountryCodes = envCountryCodes?.every((ecc) => supportedCountryCodes.includes(ecc));
  if (!isValidCountryCodes) {
    console.warn(
      `Unsupported country codes found, use all supported country codes instead:[${supportedCountryCodes}]`,
    );
  }

  const countryCodes = isValidCountryCodes
    ? envCountryCodes
    : (supportedCountryCodes as TCountryCode[]);

  const validCountryCodeEnum: { [key in SupportedCountryCode]?: TCountryCode } = {};
  for (const countryCode of countryCodes) {
    validCountryCodeEnum[countryCode] = countryCode;
  }
  return validCountryCodeEnum;
}

export enum IdentityTypeCode {
  Individual_Domestic = 'Individual_Domestic',
  SMSF = 'SMSF',
  Sole_Trader = 'Sole_Trader',
  Partnership = 'Partnership',
  Private_Company = 'Private_Company',
  Public_Company = 'Public_Company',
  Trust_Regulated = 'Trust_Regulated',
  Trust_Unregulated = 'Trust_Unregulated',
  Charity = 'Charity',
}

export enum IdentityCategory {
  Individual,
  Trust,
  Company,
  Other,
}

export const IDENTITY_TYPE_TO_CATEGORY_MAP: {
  [key in IdentityTypeCode]: IdentityCategory;
} = {
  [IdentityTypeCode.SMSF]: IdentityCategory.Trust,
  [IdentityTypeCode.Trust_Regulated]: IdentityCategory.Trust,
  [IdentityTypeCode.Trust_Unregulated]: IdentityCategory.Trust,
  [IdentityTypeCode.Private_Company]: IdentityCategory.Company,
  [IdentityTypeCode.Public_Company]: IdentityCategory.Company,
  [IdentityTypeCode.Individual_Domestic]: IdentityCategory.Individual,
  [IdentityTypeCode.Sole_Trader]: IdentityCategory.Other,
  [IdentityTypeCode.Partnership]: IdentityCategory.Other,
  [IdentityTypeCode.Charity]: IdentityCategory.Other,
};

export const IdentityTypeIndividual: TIdentityType = {
  code: IdentityTypeCode.Individual_Domestic,
  label: 'Individual',
};

export const IdentityTypeSMSF: TIdentityType = { code: IdentityTypeCode.SMSF, label: 'SMSF' };

export const IdentityTypeSoleTrader: TIdentityType = {
  code: IdentityTypeCode.Sole_Trader,
  label: 'Sole Trader',
};
export const IdentityTypePartnership: TIdentityType = {
  code: IdentityTypeCode.Partnership,
  label: 'Partnership',
};
export const IdentityTypePrivateCompany: TIdentityType = {
  code: IdentityTypeCode.Private_Company,
  label: 'Private Company',
};
export const IdentityTypePublicCompany: TIdentityType = {
  code: IdentityTypeCode.Public_Company,
  label: 'Public Company',
};
export const IdentityTypeRegulatedTrust: TIdentityType = {
  code: IdentityTypeCode.Trust_Regulated,
  label: 'Regulated Trust',
};
export const IdentityTypeUnregulatedTrust: TIdentityType = {
  code: IdentityTypeCode.Trust_Unregulated,
  label: 'Unregulated Trust',
};
export const IdentityCharity: TIdentityType = { code: IdentityTypeCode.Charity, label: 'Charity' };

export const ORGANISATION_IDENTITY_TYPES_MAP: {
  [key in IdentityTypeCode]?: TIdentityType;
} = {
  [IdentityTypeCode.SMSF]: IdentityTypeSMSF,
  [IdentityTypeCode.Sole_Trader]: IdentityTypeSoleTrader,
  [IdentityTypeCode.Partnership]: IdentityTypePartnership,
  [IdentityTypeCode.Private_Company]: IdentityTypePrivateCompany,
  [IdentityTypeCode.Public_Company]: IdentityTypePublicCompany,
  [IdentityTypeCode.Trust_Regulated]: IdentityTypeRegulatedTrust,
  [IdentityTypeCode.Trust_Unregulated]: IdentityTypeUnregulatedTrust,
  [IdentityTypeCode.Charity]: IdentityCharity,
};

export const INDIVIDUAL_IDENTITY_TYPES_MAP: { [key in IdentityTypeCode]?: TIdentityType } = {
  [IdentityTypeCode.Individual_Domestic]: IdentityTypeIndividual,
};

export const IDENTITY_TYPES_MAP: { [key in IdentityTypeCode]?: TIdentityType } = {
  ...INDIVIDUAL_IDENTITY_TYPES_MAP,
  ...ORGANISATION_IDENTITY_TYPES_MAP,
};

export const INDIVIDUAL_IDENTITY_TYPES: TIdentityType[] = [IdentityTypeIndividual];

export const ORGANISATION_IDENTITY_TYPES: TIdentityType[] = [
  IdentityTypeSMSF,
  IdentityTypeSoleTrader,
  IdentityTypePartnership,
  IdentityTypePrivateCompany,
  IdentityTypePublicCompany,
  IdentityTypeRegulatedTrust,
  IdentityTypeUnregulatedTrust,
  IdentityCharity,
];

export const IDENTITY_TYPES: TIdentityTypes = [
  ...INDIVIDUAL_IDENTITY_TYPES,
  ...ORGANISATION_IDENTITY_TYPES,
];

const SupportDocumentStatusInProgress: TSupportDocumentStatus = {
  code: EntityStatus.InProgress,
  label: 'In Progress',
};

const SupportDocumentStatusRejected: TSupportDocumentStatus = {
  code: EntityStatus.Rejected,
  label: 'Action Required',
};

const SupportDocumentStatusVerified: TSupportDocumentStatus = {
  code: EntityStatus.Verified,
  label: 'Verified',
};

const SupportDocumentStatusEmpty: TSupportDocumentStatus = {
  code: EntityStatus.Empty,
  label: 'None',
};

export const SUPPORT_DOCUMENT_STATUS: TSupportDocumentStatus[] = [
  SupportDocumentStatusInProgress,
  SupportDocumentStatusRejected,
  SupportDocumentStatusVerified,
];

export const WHOLESALE_CERTIFICATE_STATUS_MAP: {
  [key in TSupportDocumentStatusCode]: TSupportDocumentStatus;
} = {
  [EntityStatus.InProgress]: SupportDocumentStatusInProgress,
  [EntityStatus.Rejected]: SupportDocumentStatusRejected,
  [EntityStatus.Verified]: SupportDocumentStatusVerified,
  [EntityStatus.Empty]: SupportDocumentStatusEmpty,
};

export enum FileType {
  PNG = '.png',
  JPG = '.jpg',
  JPEG = '.jpeg',
  PDF = '.pdf',
}

export enum DocumentType {
  WholesaleCertificate = 'WholesaleCertificate',
  Passport = 'Passport',
  DriverLicence = 'DriverLicence',
  TrustDeed = 'TrustDeed',
  CompanyExtract = 'CompanyExtract',
  MedicareCard = 'MedicareCard',
  PartnershipAgreement = 'PartnershipAgreement',
  OtherIdentityDocument = 'OtherIdentityDocument',
  RealEstateSupportDocument = 'RealEstateSupportDocument',
  OtherSupportDocument = 'OtherSupportDocument',
}

export const STALE_DATA_EXCEPTION = 'com.greengate.verifime.utils.StaleDataException';

export const SERVER_ERRORS = {
  default:
    "Oh no! Something went wrong. Please don't worry, our support team is here to help you. Kindly reach out to them on support@verifime.com and they'll be more than happy to assist you in resolving the issue. Thank you for your patience and understanding!",
  401: "We're sorry, it looks like your access credentials are invalid or expired. Please double-check your username and password and try again. If you're still having issues, please contact our support team on support@verifime.com for further assistance. Thank you!",
  400: 'You are probably missing the "entityType"',
  500: {
    [STALE_DATA_EXCEPTION]:
      'Some information is out of date, please refresh the page and try again.',
  },
};

export const basicFieldsPrefix = 'basicSepa';
export const passportFieldsPrefix = 'passportSepa';
export const driverLicenceFieldsPrefix = 'driverLicenceSepa';
export const addressFieldsPrefix = 'addressSepa';
export const registeredOfficeFieldsPrefix = 'registeredOfficeSepa';
export const principalPlaceOfBusinessFieldsPrefix = 'principalPlaceOfBusinessSepa';
export const medicareCardFieldsPrefix = 'medicareCardSepa';

export enum UnregulatedTrustType {
  FamilyTrust = 'Family Trust',
  CharitableTrust = 'Charitable Trust',
  TestamentaryTrust = 'Testamentary Trust',
  UnitTrust = 'Unit Trust',
  OtherType = 'Other Type',
}

export enum RegulatedTrustType {
  RegisteredManagedInvestmentScheme = 'Registered Managed Investment Scheme',
  UnregisteredManagedInvestmentScheme = 'Unregistered Managed Investment Scheme',
  RegulatedUnderACommonwealthStatutoryRegulator = 'Regulated Under A Commonwealth Statutory Regulator',
  GovernmentSuperannuationFund = 'Government Superannuation Fund',
}

export const TrustType = { ...UnregulatedTrustType, ...RegulatedTrustType };

export enum ExchangeListingType {
  DomesticExchange = 'Domestic Exchange',
  InternationalExchange = 'International Exchange',
  Unlisted = 'Unlisted',
}

export const EXCHANGE_LISTING_TYPE = { ...ExchangeListingType };
export const EXCHANGE_LISTING_TYPE_OPTIONS = enumToOptions(ExchangeListingType);

export enum UserCustomerRole {
  Controller = 'Controller',
  Viewer = 'Viewer',
  NotApplicable = 'NotApplicable',
}

export const LEARN_MORE_PASSKEY_URL = 'https://developers.google.com/identity/passkeys';
export const LEARN_MORE_AUTHENTICATOR_URL =
  'https://www.keepersecurity.com/blog/2023/07/20/what-are-authenticator-apps-and-how-do-they-work/';

/**
 * Represents the possible verification status values.
 */
export type TStatus =
  | 'Verified' // Verification is successful
  | 'Rejected' // Verification is rejected
  | 'InProgress' // Verification is in progress
  | 'Expired' // Associated verification document has expired
  | 'NotAvailable' // Verification not started
  | 'Matched' // Matched with PEP or Sanction list
  | 'NotMatched'; // Not matched with PEP or Sanction list

export const OtherIdentityDocumentOptions: { [documentType: string]: TOption } = {
  NationalID: { code: 'NationalID', label: 'National ID' },
};
