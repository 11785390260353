import { Alert, ColoredIcon } from '@verifime/components';
import { ReactNode } from 'react';

export default function Declaration({ declaration }: Readonly<{ declaration: ReactNode }>) {
  return (
    <Alert icon={<ColoredIcon iconName="LockRounded" />} severity="info">
      {declaration}
    </Alert>
  );
}
