import React, { ChangeEventHandler, SyntheticEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import * as otpauth from 'otpauth';
import { LEARN_MORE_AUTHENTICATOR_URL, Credential } from '@verifime/utils';
import tokens from '@verifime/design-tokens';
import { IconLinkOpenNew } from '../../IconLinkOpenNew';

export type TAuthenticatorCode = {
  totp: otpauth.TOTP;
  registerOTP: (secret: string | (() => string | Promise<string>)) => Promise<string>;
  onDone: (credentialSetupStatus: Credential) => void;
  otpDigits?: number;
};

export const AuthenticatorCode = ({
  totp,
  registerOTP,
  onDone,
  otpDigits = 6,
}: TAuthenticatorCode) => {
  const [code, setCode] = useState<string>('');
  const [codeInvalid, setCodeInvalid] = useState<boolean>(false);

  const handleOnCodeInputChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const entered = e.target.value;
    if (entered === '') {
      setCodeInvalid(false);
    }
    setCode(entered);
  };

  const completeOtpSetup = (e: SyntheticEvent) => {
    e.preventDefault();

    if (totp.validate({ token: code, window: 1 }) === null) {
      setCodeInvalid(true);
      return;
    }

    registerOTP(totp.secret.base32)
      .then(() => {
        onDone(Credential.ByAuthenticator);
      })
      .finally(() => setCodeInvalid(false));
  };

  return (
    <>
      <Stack gap={tokens.spacingBase}>
        <Typography variant="body1">
          Enter the {otpDigits} digit code shown on your authenticator app
        </Typography>
        <Stack alignItems="center" gap={tokens.spacingBase}>
          <TextField
            value={code}
            onChange={handleOnCodeInputChanged}
            fullWidth
            variant="outlined"
            size="small"
            type="number"
            autoFocus
            label={`${otpDigits} digit code`}
            error={codeInvalid}
            helperText={codeInvalid ? 'invalid code' : ''}
            sx={{
              '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
              '& input[type="number"]': {
                MozAppearance: 'textfield',
              },
            }}
          />
          <IconLinkOpenNew href={LEARN_MORE_AUTHENTICATOR_URL}>
            Learn more about Authenticator apps
          </IconLinkOpenNew>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        type="button"
        disabled={!code?.match(new RegExp(`^\\d{${otpDigits}}$`))}
        onClick={completeOtpSetup}
      >
        Done
      </Button>
    </>
  );
};

export default AuthenticatorCode;
